import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import DeletePage from './components/pages/DeletePage';
import ApprovePage from './components/pages/ApprovePage';
import HomePage from './components/pages/HomePage';
import ErrorPage from './components/pages/ErrorPage';
import { RemoveCircleOutlineOutlined } from '@material-ui/icons';
import CreatePage from './components/pages/CreatePage';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core';

let theme = createMuiTheme({});
theme = responsiveFontSizes(theme);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route exact={true} path={'/'}>
            <HomePage />
          </Route>
          <Route path={'/approve/:hash'}>
            <ApprovePage />
          </Route>
          <Route path={'/delete/:hash'}>
            <DeletePage />
          </Route>
          <Route exact={true} path={'/create'}>
            <GoogleReCaptchaProvider
              reCaptchaKey="6LdRtlcaAAAAAGTYkC89hJtAfwUUEIYHOZO91xzB"
              language="cs"
              useRecaptchaNet={false}
              scriptProps={{
                async: false,
                defer: false,
                appendTo: 'head',
                nonce: undefined
              }}
            >
              <CreatePage />
            </GoogleReCaptchaProvider>
          </Route>
          <Route path="*">
            <ErrorPage message='Stránka nebyla nalezena' icon={RemoveCircleOutlineOutlined} />;
          </Route>
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
