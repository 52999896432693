import React from 'react';
import { Reservation } from '../services/types';
import { Card, CardContent, CardHeader, Divider, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import Moment from './Moment';
import PersonIcon from '@material-ui/icons/Person';
import DraftsIcon from '@material-ui/icons/Drafts';
import PhoneIcon from '@material-ui/icons/Phone';
import TimerIcon from '@material-ui/icons/Timer';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import ReservationStatus from './ReservationStatus';
import { makeStyles } from '@material-ui/core/styles';

interface Props {
  reservation: Reservation
}

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 500,
    margin: 'auto'
  },
  highlightedCardArea: {
    color: '#fff',
    backgroundColor: theme.palette.warning.light
  }
}));

const ReservationDetail: React.FC<Props> = (props) => {
  const reservation = props.reservation;
  const styles = useStyles();

  return (<>
      <Card className={styles.root}>
        <CardHeader
          title={reservation.purpose}
          subheader={reservation.note}
        />
        <CardContent className={styles.highlightedCardArea}>
          <Typography variant="h6" component="p">
            <Moment value={reservation.termFrom} /> - <Moment value={reservation.termTo} />
          </Typography>
        </CardContent>
        <CardContent>
          <List>
            {reservation.requesterName &&
            <ListItem>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText primary={reservation.requesterName} />
            </ListItem>
            }
            {reservation.requesterEmail &&
            <ListItem>
              <ListItemIcon>
                <DraftsIcon />
              </ListItemIcon>
              <ListItemText primary={reservation.requesterEmail} />
            </ListItem>
            }
            {reservation.requesterPhone &&
            <ListItem>
              <ListItemIcon>
                <PhoneIcon />
              </ListItemIcon>
              <ListItemText primary={reservation.requesterPhone} />
            </ListItem>
            }
          </List>
          <Divider />
          <List>
            <ListItem>
              <ListItemIcon>
                <TimerIcon />
              </ListItemIcon>
              <Moment value={reservation.createdTimestamp} />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <DonutLargeIcon />
              </ListItemIcon>
              <ListItemText primary={<ReservationStatus status={reservation.status} />} />
            </ListItem>
          </List>
        </CardContent>
      </Card>
    </>
  );
};

export default ReservationDetail;
