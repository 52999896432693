import React from 'react';
import { SvgIconComponent } from '@material-ui/icons';
import { Typography } from '@material-ui/core';
import Page from './Page';
import HomeLinkButton from '../HomeLinkButton';

interface ErrorPageProps {
  message: string,
  icon: SvgIconComponent
}

const ErrorPage: React.FC<ErrorPageProps> = (props) => {
  const Icon = props.icon;
  return (<Page>
    <Icon style={{ fontSize: 50 }} color={'error'} />
    <Typography variant="h6" gutterBottom color={'textSecondary'}>
      {props.message}
    </Typography>
    <HomeLinkButton />
  </Page>);
};

export default ErrorPage;
