import React, { useEffect, useState } from 'react';
import reservationService from '../../services/reservationService';
import { useLocation, useParams } from 'react-router-dom';
import { FetchStatus, Reservation, ReservationStatus, SaveResult } from '../../services/types';
import FetchingPageContainer from '../FetchingPageContainer';
import ReservationDetail from '../ReservationDetail';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EventSubmitButton from '../EventSubmitButton';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import AlertMessage from '../AlertMessage';
import HomeLinkButton from '../HomeLinkButton';

const useStyles = makeStyles(theme => ({
  success: {
    color: theme.palette.success.main
  }
}));

const REQUESTER_URL_PARAMETER = 'requester';
const REQUESTER_MANAGER_URL_VALUE = 'manager';

function useQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search);
}

const DeletePage: React.FC = () => {
  const [fetchStatus, setFetchStatus] = useState<FetchStatus>(FetchStatus.LOADING);
  const [reservation, setReservation] = useState<Reservation>();
  const [saveStatus, setSaveStatus] = useState<SaveResult<Reservation>>();
  const query = useQuery();

  let { hash } = useParams();
  const classes = useStyles();

  useEffect(() => {
    (async function () {
      const result = await reservationService.findForDelete(hash);
      setFetchStatus(result.status);
      setReservation(result.data);
    })();
  }, [hash]);

  const sendRequest = async () => {
    if (reservation?.uuid) {
      setSaveStatus({ status: FetchStatus.LOADING });
      const result = await reservationService.delete(hash);
      setSaveStatus(result);
      if (result.data) {
        setReservation(result.data);
      }
    }
  };

  return (
    <FetchingPageContainer fetchStatus={fetchStatus}>
      {reservation && <>
        <Typography variant={'h2'} gutterBottom={true}>Zrušení rezervace</Typography>
        <ReservationDetail reservation={reservation} />
        <Box mt={4}>

          {reservation.status === ReservationStatus.DELETED &&
          <Box mt={4}>
            <CheckCircleOutlineIcon style={{ fontSize: 50 }} className={classes.success} />
            <Typography variant="h6" gutterBottom color={'textSecondary'}>
              <p><strong>Rezervace byla zrušena.</strong></p>
              {query.get(REQUESTER_URL_PARAMETER) === REQUESTER_MANAGER_URL_VALUE
                ? 'Na e-mail žadatele bylo zasláno oznámení o zrušení rezervace budovy.'
                : 'Na e-mail správce bylo zasláno oznámení o zrušení rezervace budovy.'
              }
            </Typography>
          </Box>
          }

          {saveStatus?.status === FetchStatus.ERROR &&
          <AlertMessage>
            {!saveStatus.errors && <>Nepodařilo se smazat rezervaci, zkuste to prosím později.</>}
          </AlertMessage>
          }

          {(reservation.status === ReservationStatus.CREATED || reservation.status === ReservationStatus.APPROVED) &&
          <Box mt={4}>
            <EventSubmitButton
              defaultText='Zrušit žádost'
              loadingText='Ruším žádost...'
              successText='Zrušeno'
              saveStatus={saveStatus?.status}
              onClick={sendRequest} />
          </Box>
          }
        </Box>
        <HomeLinkButton />
      </>}
    </FetchingPageContainer>
  );
};

export default DeletePage;
