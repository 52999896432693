import React from 'react';
import moment from 'moment';

interface Props {
  value: string
}

const dateTimeFormat = 'D.M.YYYY H:mm';

const Moment: React.FC<Props> = (props) => {
  return <>{moment(props.value).format(dateTimeFormat)}</>;
};

export default Moment;
