import * as React from 'react';
import { FieldProps } from 'formik';
import { TimePicker, TimePickerProps } from '@material-ui/pickers';
import { TextField } from '@material-ui/core';
import { Moment } from 'moment';

interface DatePickerFieldProps extends FieldProps, TimePickerProps {
  getShouldDisableTimeError: (time: Moment) => string;
}

const DATE_FORMAT = 'HH:mm';

//see https://next.material-ui-pickers.dev/guides/forms
function TimePickerField({
  form,
  field: { value, name },
  label,
  getShouldDisableTimeError = () => '',
  ...other
}: DatePickerFieldProps) {
  const currentError = form.errors[name];
  const toShowError = Boolean(currentError && form.touched[name]);

  return (
    <TimePicker
      clearable
      disabled={form.isSubmitting}
      label={label}
      className={'timePick'}
      value={value}
      mask="__:__"
      onError={(reason, value) => {
        switch (reason) {
          case 'invalidDate':
            form.setFieldError(name, `Neplatný formát času, datum musí být ve formátu ${DATE_FORMAT}.`);
            break;
          case 'shouldDisableTime-hours':
            // shouldDisableDate returned true, render custom message according to the `shouldDisableDate` logic
            form.setFieldError(name, getShouldDisableTimeError(value as Moment));
            break;
          case 'shouldDisableTime-minutes':
            // shouldDisableDate returned true, render custom message according to the `shouldDisableDate` logic
            form.setFieldError(name, getShouldDisableTimeError(value as Moment));
            break;
          default:
            form.setErrors({
              ...form.errors,
              [name]: undefined
            });
        }
      }}
      // Make sure that your 3d param is set to `false` on order to not clear errors
      onChange={(time) => {
        form.setFieldValue(name, time, false);
      }}
      renderInput={(props) => (
        <TextField
          {...props}
          className={'timePick'}
          name={name}
          error={toShowError}
          helperText={toShowError ? currentError ?? props.helperText : undefined}
          // Make sure that your 3d param is set to `false` on order to not clear errors
          onBlur={() => form.setFieldTouched(name, true, false)}
        />
      )}
      inputFormat={DATE_FORMAT}
      {...other}
    />
  );
}

export default TimePickerField;