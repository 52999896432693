import React from 'react';

export default () => {
  return <div>
    <iframe
      title="Kalendář rezervací budovy"
      src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=2&amp;bgcolor=%23ffffff&amp;ctz=Europe%2FPrague&amp;src=bW9oZWxub0Bzb2tvbC5ldQ&amp;color=%23A79B8E&amp;showTabs=1&amp;showCalendars=0&amp;showTz=0&amp;showPrint=0&amp;showDate=1&amp;showNav=1&amp;showTitle=0&amp;mode=WEEK"
      width="800"
      height="600"
      frameBorder="0"
      scrolling="no" />
  </div>;
}