import React from 'react';
import logo from './sokolo-mohelno-logo.png';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

interface Props {
  headerClass?: string
}

const useStyles = makeStyles(() => ({
  logo: {
    maxWidth: '300px',
    maxHeight: '100px',
    width: '100%',
    margin: 'auto',
    objectFit: 'contain'
  },
  headerContent: {
    width: '100%',
    height: '100%',
    display: 'flex'
  }
}));

const Header: React.FC<Props> = (props) => {
  const classes = useStyles();
  return <header className={props.headerClass}>

    <Box textAlign={'center'} className={classes.headerContent}>
      <Link to={'/'} className={classes.logo}>
        <img src={logo} alt="Sokol Mohelno logo" className={classes.logo} />
      </Link>
    </Box>
  </header>;
};

export default Header;
