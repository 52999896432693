import configuration from '../configuration';
import { FetchResult, FetchStatus, Reservation, ReservationRequest, SaveResult } from './types';
import moment from 'moment/moment';

class ReservationService {
  public async findForDelete(hash: string): Promise<FetchResult<Reservation>> {
    return ReservationService.find('by-delete', hash);
  }

  public async findForApprove(hash: string): Promise<FetchResult<Reservation>> {
    return ReservationService.find('by-approve', hash);
  }

  public async approve(uuid: string): Promise<FetchResult<Reservation>> {
    return this.save(new FormData(), `/by-approve/${uuid}`);
  }

  public async delete(uuid: string): Promise<FetchResult<Reservation>> {
    return this.save(new FormData(), `/by-delete/${uuid}`);
  }

  public async saveReservationRequest(request: ReservationRequest): Promise<SaveResult<Reservation>> {
    const formData = new URLSearchParams();
    Object.entries(request).forEach(([key, value]) => {
      if (value) {
        if (value instanceof Date) {
          formData.set(key, moment(value).format());
        } else {
          formData.set(key, value);
        }
      }
    });
    return this.save(formData, undefined);
  }

  private static async find(urlPart: string, hash: string): Promise<FetchResult<Reservation>> {
    try {
      return ReservationService.fetchData(urlPart, hash);
    } catch (e) {
      console.error('Error while fetching', e);
      return { status: FetchStatus.ERROR };
    }
  }

  private static async fetchData(urlPart: string, hash: string) {
    const fetched = await fetch(`${configuration.reservationsApiUrl}/${urlPart}/${hash}`);
    if (fetched.ok) {
      const result = await fetched.json();
      return {
        status: FetchStatus.SUCCESS,
        data: result
      };
    } else if (fetched.status === 404) {
      return {
        status: FetchStatus.NOT_FOUND
      };
    } else {
      return { status: FetchStatus.ERROR };
    }
  }

  private async save(data: FormData, urlPostfix?: string): Promise<SaveResult<Reservation>> {
    try {
      const fetched = await fetch(`${configuration.reservationsApiUrl}${urlPostfix ?? ''}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: data
      });
      if (fetched.ok) {
        const result = await fetched.json();
        return {
          status: FetchStatus.SUCCESS,
          data: result
        };
      } else if (fetched.status === 422) {
        console.error('Error while fetching saved request', fetched);
        const result = await fetched.json();
        return {
          status: FetchStatus.ERROR,
          errors: Object.entries(result)
        };
      } else {
        console.error('Error while fetching saved request');
        return { status: FetchStatus.ERROR };
      }
    } catch (e) {
      console.error('Error while saving request', e);
      return { status: FetchStatus.ERROR };
    }
  }
}

export default new ReservationService();