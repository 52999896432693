import React, { useState } from 'react';
import Page from './Page';
import { Box, Step, StepLabel, Stepper, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import EventCreate from '../EventCreate';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { makeStyles } from '@material-ui/core/styles';
import HighlightedSection from '../HighlightedSection';
import HomeLinkButton from '../HomeLinkButton';

const useStyles = makeStyles(theme => ({
  success: {
    color: theme.palette.success.main
  },
  stepper: {
    background: 'none',
    [theme.breakpoints.down('md')]: {
      textAlign: 'left'
    }
  }
}));

export enum RequestStatus {
  CREATING,
  CREATED
}

const CreatePage: React.FC = () => {
  const theme = useTheme();
  const showStepper = useMediaQuery(theme.breakpoints.up('md'));
  const classes = useStyles();
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.CREATING);
  const switchRequestStatus = (status: RequestStatus) => {
    setRequestStatus(status);
  };
  return (<Page>
    <HighlightedSection>
      <Typography variant={'h2'} gutterBottom={true}>Nová rezervace budovy</Typography>
      <Box mt={4}>
        {showStepper &&
        <>
          <Typography variant={'h6'} gutterBottom={true}>Jak to funguje?</Typography>
          <Stepper activeStep={requestStatus} alternativeLabel className={classes.stepper}>
            <Step>
              <StepLabel>Vyplníte žádost o rezervaci</StepLabel>
            </Step>
            <Step>
              <StepLabel>Vy i správce budovy obrdržíte potvrzení žádosti e-mailem</StepLabel>
            </Step>
            <Step>
              <StepLabel>Žádost schválí správce budovy</StepLabel>
            </Step>
            <Step>
              <StepLabel>Obdržíte e-mailovou notifikaci o schválení</StepLabel>
            </Step>
            <Step>
              <StepLabel>Vyzvednete si klíče</StepLabel>
            </Step>
          </Stepper>
        </>
        }
      </Box>
    </HighlightedSection>
    <Box mt={4}>
      {requestStatus === RequestStatus.CREATING &&
      <>
        <Typography variant={'h5'} gutterBottom={true}>Zadání žádosti</Typography>
        <EventCreate switchRequestStatus={switchRequestStatus} />
      </>
      }
      {requestStatus === RequestStatus.CREATED &&
      <>
        <CheckCircleOutlineIcon style={{ fontSize: 50 }} className={classes.success} />
        <Typography variant="h5" gutterBottom color={'textSecondary'}>
          <p><strong>Vaše rezervace byla odeslána ke schválení.</strong></p>
          Na Váš e-mail byly odeslány zadané údaje rezervace, avšak <strong>vyčkejte prosím na e-mail s potvrzením od správce budovy</strong>.
        </Typography>
        <HomeLinkButton />
      </>
      }
    </Box>
  </Page>);
};

export default CreatePage;
