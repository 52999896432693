import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Header from '../Header';
import Footer from '../Footer';

const useStyles = makeStyles((theme) => ({
  grid: {
    height: '100%',
    display: 'grid',
    gridTemplateColumns: `[left-start] auto [main-start] auto [right-start] auto [right-end]`,
    gridTemplateRows: '[header-start] 160px [content-start] auto [footer-start] 100px [footer-end]'
  },
  header: {
    gridColumnStart: 'left-start',
    gridColumnEnd: 'right-end',
    gridRowStart: 'header-start',
    gridRowEnd: 'content-start'
  },
  content: {
    gridColumnStart: 'left-start',
    gridColumnEnd: 'right-end',
    gridRowStart: 'content-start',
    gridRowEnd: 'footer-start'
  },
  contentInner: {
    textAlign: 'center'
  },
  footer: {
    gridColumnStart: 'left-start',
    gridColumnEnd: 'right-end',
    gridRowStart: 'footer-start',
    gridRowEnd: 'footer-end',
    backgroundColor: theme.palette.grey[100]
  }
}));

const Page: React.FC = (props) => {
  const classes = useStyles();

  return <Box className={classes.grid}>
    <Header headerClass={classes.header} />
    <Box className={classes.content}>
      <Box display="flex" mb={6} className={classes.contentInner}>
        <Box style={{ width: '100%' }}>{props.children}</Box>
      </Box>
    </Box>
    <Footer footerClass={classes.footer} />
  </Box>;
};

export default Page;
