import * as React from 'react';
import { FieldProps } from 'formik';
import { DatePicker, DatePickerProps } from '@material-ui/pickers';
import { TextField } from '@material-ui/core';
import moment, { Moment } from 'moment';

interface DatePickerFieldProps extends FieldProps, DatePickerProps {
  getShouldDisableDateError: (date: Moment) => string;
}

const DATE_FORMAT = 'DD.MM.YYYY';

//see https://next.material-ui-pickers.dev/guides/forms
function DatePickerField({
  form,
  field: { value, name },
  maxDate = moment().add(100, 'years'),
  minDate = moment(),
  label,
  getShouldDisableDateError = () => '',
  ...other
}: DatePickerFieldProps) {
  const currentError = form.errors[name];
  const toShowError = Boolean(currentError && form.touched[name]);

  return (
    <DatePicker
      clearable
      disabled={form.isSubmitting}
      label={label}
      minDate={minDate}
      maxDate={maxDate}
      value={value}
      mask="__.__.____"
      onError={(reason, value) => {
        switch (reason) {
          case 'invalidDate':
            form.setFieldError(name, `Neplatný formát data, datum musí být ve formátu ${DATE_FORMAT}.`);
            break;

          case 'disablePast':
            form.setFieldError(name, 'Datum nemůže být v minulosti');
            break;

          case 'maxDate':
            form.setFieldError(name, `Datum nemůže být po ${(maxDate as Moment).format(DATE_FORMAT)}`);
            break;

          case 'minDate':
            form.setFieldError(name, `Datum nemůže být před ${(minDate as Moment).format(DATE_FORMAT)}`);
            break;

          case 'shouldDisableDate':
            // shouldDisableDate returned true, render custom message according to the `shouldDisableDate` logic
            form.setFieldError(name, getShouldDisableDateError(value as Moment));
            break;

          default:
            form.setErrors({
              ...form.errors,
              [name]: undefined
            });
        }
      }}
      // Make sure that your 3d param is set to `false` on order to not clear errors
      onChange={(date) => form.setFieldValue(name, date, false)}
      renderInput={(props) => (
        <TextField
          {...props}
          name={name}
          error={toShowError}
          helperText={toShowError ? currentError ?? props.helperText : undefined}
          // Make sure that your 3d param is set to `false` on order to not clear errors
          onBlur={() => form.setFieldTouched(name, true, false)}
          inputProps={{
            ...props.inputProps,
            pattern: '[0-9]{1,2}.[0-9]{1,2}.[0-9]{4}'
          }}
        />
      )}
      inputFormat={DATE_FORMAT}
      {...other}
    />
  );
}

export default DatePickerField;