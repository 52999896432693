import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

interface Props {
}

const useStyles = makeStyles((theme) => ({
  sectionReservation: {
    backgroundColor: theme.palette.grey[100]
  }
}));

const HighlightedSection: React.FC<Props> = (props) => {
  const classes = useStyles();

  return <Box mb={5} pt={3} pb={3} component={'section'} className={classes.sectionReservation}>
    {props.children}
  </Box>;
};

export default HighlightedSection;
