import React, { useEffect, useState } from 'react';
import reservationService from '../../services/reservationService';
import { useParams } from 'react-router-dom';
import { FetchStatus, Reservation, ReservationStatus, SaveResult } from '../../services/types';
import FetchingPageContainer from '../FetchingPageContainer';
import ReservationDetail from '../ReservationDetail';
import { Box, Typography } from '@material-ui/core';
import EventSubmitButton from '../EventSubmitButton';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { makeStyles } from '@material-ui/core/styles';
import AlertMessage from '../AlertMessage';
import HomeLinkButton from '../HomeLinkButton';

const useStyles = makeStyles(theme => ({
  success: {
    color: theme.palette.success.main
  }
}));

const ApprovePage: React.FC = () => {
  const [fetchStatus, setFetchStatus] = useState<FetchStatus>(FetchStatus.LOADING);
  const [saveStatus, setSaveStatus] = useState<SaveResult<Reservation>>();
  const [reservation, setReservation] = useState<Reservation>();
  const classes = useStyles();
  let { hash } = useParams();

  useEffect(() => {
    (async function () {
      const result = await reservationService.findForApprove(hash);
      setFetchStatus(result.status);
      setReservation(result.data);
    })();
  }, [hash]);

  const sendRequest = async () => {
    if (reservation?.uuid) {
      setSaveStatus({ status: FetchStatus.LOADING });
      const result = await reservationService.approve(hash);
      setSaveStatus(result);
      if (result.data) {
        setReservation(result.data);
      }
    }
  };

  return (
    <FetchingPageContainer fetchStatus={fetchStatus}>
      {reservation && <>
        <Typography variant={'h2'} gutterBottom={true}>Schválení rezervace</Typography>
        <ReservationDetail reservation={reservation} />

        <Box mt={4}>
          {reservation.status === ReservationStatus.APPROVED &&
          <Box mt={4}>
            <CheckCircleOutlineIcon style={{ fontSize: 50 }} className={classes.success} />
            <Typography variant="h6" gutterBottom color={'textSecondary'}>
              <p><strong>Rezervace byla schválena.</strong></p>
              Na e-mail žadatele bylo již zasláno potvrzení o rezervaci s dalšími pokyny.
            </Typography>
          </Box>
          }

          {saveStatus?.status === FetchStatus.ERROR &&
          <AlertMessage>
            {!saveStatus.errors && <>Nepodařilo se schválit rezervaci, zkuste to prosím později.</>}
          </AlertMessage>
          }

          {reservation.status === ReservationStatus.CREATED &&
          <Box mt={4}>
            <EventSubmitButton
              defaultText='Schválit žádost'
              loadingText='Schvaluji...'
              successText='Schváleno'
              saveStatus={saveStatus?.status}
              onClick={sendRequest} />
          </Box>
          }
        </Box>
        <HomeLinkButton />
      </>}
    </FetchingPageContainer>
  );
};

export default ApprovePage;
