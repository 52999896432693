import React from 'react';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  alert: {
    textAlign: 'left',
    maxWidth: '500px',
    margin: 'auto'
  }
}));

interface Props {
}

const AlertMessage: React.FC<Props> = (props) => {
  const classes = useStyles();
  return <Alert severity={'error'} className={classes.alert}>
    {props.children}
  </Alert>;
};

export default AlertMessage;
