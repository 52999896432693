import React, { ReactElement } from 'react';
import { Button, PropTypes } from '@material-ui/core';
import { FetchStatus } from '../services/types';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CheckIcon from '@material-ui/icons/Check';
import SendIcon from '@material-ui/icons/Send';

interface Props {
  saveStatus?: FetchStatus,
  loadingText: string,
  successText: string,
  defaultText: string,
  onClick?: () => void
}

const EventSubmitButton: React.FC<Props> = (props) => {

  const findButtonAttributes = (): { icon: ReactElement, text: string, color: PropTypes.Color, disabled: boolean } => {
    switch (props.saveStatus) {
      case FetchStatus.LOADING:
        return {
          icon: <AutorenewIcon />,
          text: props.loadingText,
          color: 'primary',
          disabled: true
        };
      case FetchStatus.SUCCESS:
        return {
          icon: <CheckIcon />,
          text: props.successText,
          color: 'default',
          disabled: true
        };
      default:
        return {
          icon: <SendIcon />,
          text: props.defaultText,
          color: 'primary',
          disabled: false
        };
    }
  };
  const buttonAttributes = findButtonAttributes();

  return <Button
    variant="contained"
    color={buttonAttributes.color}
    size="large"
    type={'submit'}
    disabled={buttonAttributes.disabled}
    startIcon={buttonAttributes.icon}
    onClick={() => {
      if (props.onClick) {
        props.onClick();
      }
    }}
  >
    {buttonAttributes.text}
  </Button>;
};

export default EventSubmitButton;
