//TODO PCM predelat na typescript types

export enum FetchStatus {
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  NOT_FOUND = 'NOT_FOUND',
  SUCCESS = 'SUCCESS'
}

export enum ReservationStatus {
  CREATED = 'CREATED',
  DELETED = 'DELETED',
  APPROVED = 'APPROVED'
}

export interface FetchResult<T> {
  status: FetchStatus,
  data?: T
}

export interface SaveResult<T> extends FetchResult<T> {
  errors?: Array<[string, Array<string>]>,
}

export interface Reservation {
  uuid: string,
  termFrom: string,
  termTo: string,
  purpose: string,
  note?: string,
  requesterName?: string,
  requesterEmail: string,
  requesterPhone?: string,
  status: ReservationStatus,
  createdTimestamp: string,
  updatedTimestamp: string,
  updatedUser: string,
  deleteToken: string,
  approveToken: string,
  googleCalendarId: string
}

export type ReservationRequest =
  Pick<Reservation, 'purpose' | 'note' | 'requesterName' | 'requesterEmail' | 'requesterPhone'>
  | { termFrom: Date, termTo: Date, captchaToken: string };