import React, { useState } from 'react';
import { Box, createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LocalizationProvider } from '@material-ui/pickers';
import MomentAdapter from '@material-ui/pickers/adapter/moment';
import moment, { Moment } from 'moment/moment';
import 'moment/locale/cs';
import { FetchStatus, Reservation, SaveResult } from '../services/types';
import { Alert } from '@material-ui/lab';
import { RequestStatus } from './pages/CreatePage';
import localizationService from '../services/localizationService';
import EventSubmitButton from './EventSubmitButton';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import DatePickerField from './DatePickerField';
import TimePickerField from './TimePickerField';
import { TextField } from 'formik-material-ui';
import reservationService from '../services/reservationService';
import HomeLinkButton from './HomeLinkButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      maxWidth: '500px',
      [theme.breakpoints.down('xs')]: {
        paddingRight: theme.spacing(2)
      },
      margin: 'auto',
      '& .MuiTextField-root': {
        margin: theme.spacing(1)
      },
      '& .MuiFormControl-marginNormal': {
        textAlign: 'left',
        width: '50%'
      },
      '& .timePick': {
        maxWidth: '150px'
      }
    }
  })
);

const validateTimeRange = (
  termFromDate?: Moment | null, termToDate?: Moment | null, termFromTime?: Moment | null, termToTime?: Moment | null): boolean => {
  if (termToDate == null || termFromDate == null || termToTime == null || termFromTime == null) {
    return true;
  }
  if (!termToDate.isSame(termFromDate, 'day')) {
    return true;
  }
  return termToTime.hour() > termFromTime.hour();
};

const validateDateRange = (termFromDate?: Moment | null, termToDate?: Moment | null): boolean => {
  if (termFromDate == null || termToDate == null) {
    return true;
  }
  return termFromDate.isSameOrBefore(termToDate, 'day');
};

interface ReservationFormFields {
  purpose: string,
  note?: string,
  requesterName: string,
  requesterEmail: string,
  requesterPhone?: string,
  termFromDate: Moment,
  termFromTime: Moment,
  termToDate: Moment,
  termToTime: Moment
}

function mapErrorFieldToForm(errorField: string) {
  if (errorField.startsWith('term')) {
    return errorField + 'Date';
  }
  if (errorField.startsWith('captcha')) {
    return 'requesterName';
  }
  return errorField;
}

const EventCreate: React.FC<{ switchRequestStatus: (status: RequestStatus) => void }> = (props) => {
  const currentMoment = moment().startOf('hour').add(1, 'h');
  currentMoment.locale('cs');
  const { executeRecaptcha } = useGoogleReCaptcha();
  const from = currentMoment;
  const to = moment(currentMoment).add(1, 'h');
  const [saveStatus, setSaveStatus] = useState<SaveResult<Reservation>>();

  const onSubmit = async (values: ReservationFormFields, helpers: FormikHelpers<ReservationFormFields>) => {
    helpers.setSubmitting(true);
    setSaveStatus({ status: FetchStatus.LOADING });
    const termTo = moment(values.termToDate).hours(values.termToTime.hours()).minutes(values.termToTime.minutes()).toDate();
    const termFrom = moment(values.termFromDate).hours(values.termFromTime.hours()).minutes(values.termFromTime.minutes()).toDate();
    const token = executeRecaptcha ? await executeRecaptcha('event_create') : undefined;

    const result = await reservationService.saveReservationRequest({
      requesterPhone: values.requesterPhone,
      requesterEmail: values.requesterEmail,
      requesterName: values.requesterName,
      note: values.note,
      purpose: values.purpose,
      termTo: termTo,
      termFrom: termFrom,
      captchaToken: token
    });
    helpers.setSubmitting(false);
    if (result.status === FetchStatus.ERROR) {
      result.errors?.forEach(errorField => {
        const field = mapErrorFieldToForm(errorField[0]);
        errorField[1].forEach(error => {
          const message = localizationService.localize(error);
          console.log(field, message);
          helpers.setFieldError(field, message);
        });
      });
    }
    setSaveStatus(result);
    if (result.status === FetchStatus.SUCCESS) {
      props.switchRequestStatus(RequestStatus.CREATED);
    }
  };

  const classes = useStyles();

  const SignupSchema = Yup.object()
    .shape({
      requesterName: Yup.string()
        .min(2, localizationService.localize('validation.TOO_SHORT'))
        .max(255, localizationService.localize('validation.TOO_LONG'))
        .required(localizationService.localize('validation.REQUIRED')),
      requesterEmail: Yup.string()
        .required(localizationService.localize('validation.REQUIRED'))
        .email(localizationService.localize('validation.MAIL')),
      requesterPhone: Yup.string()
        .required(localizationService.localize('validation.REQUIRED'))
        .matches(/^[+]?[()/0-9. -]{9,}$/, localizationService.localize('validation.PHONE')),
      purpose: Yup.string()
        .min(2, localizationService.localize('validation.TOO_SHORT'))
        .max(255, localizationService.localize('validation.TOO_LONG'))
        .required(localizationService.localize('validation.REQUIRED')),
      note: Yup.string()
        .min(2, localizationService.localize('validation.TOO_SHORT'))
        .max(255, localizationService.localize('validation.TOO_LONG')),
      termFromDate: Yup.mixed()
        .required(localizationService.localize('validation.REQUIRED')),
      termToDate: Yup.mixed()
        .required(localizationService.localize('validation.REQUIRED'))
        .test('termToDate', localizationService.localize('validation.reservation.end'), function (value: unknown) {
          return validateDateRange(this.parent.termFromDate as Moment, value as Moment);
        }),
      termFromTime: Yup.mixed()
        .required(localizationService.localize('validation.REQUIRED'))
        .test('termFromTime', localizationService.localize('validation.reservation.minimal'), function (value: unknown) {
          return validateTimeRange(this.parent.termFromDate as Moment, this.parent.termToDate as Moment, value as Moment,
            this.parent.termToTime as Moment);
        }),
      termToTime: Yup.mixed()
        .required(localizationService.localize('validation.REQUIRED'))
        .test('termToTime', localizationService.localize('validation.reservation.minimal'), function (value: unknown) {
          return validateTimeRange(this.parent.termFromDate as Moment, this.parent.termToDate as Moment, this.parent.termFromTime as Moment,
            value as Moment);
        })
    });

  const defaultValues: ReservationFormFields = {
    purpose: '',
    note: '',
    requesterName: '',
    requesterEmail: '',
    requesterPhone: '',
    termFromDate: from,
    termFromTime: from,
    termToDate: to,
    termToTime: to
  };
  return <Formik initialValues={defaultValues} onSubmit={onSubmit}
    validationSchema={SignupSchema}>
    {({
      handleSubmit,
      isValid,
      submitCount
    }) => (
      <Form className={classes.form} onSubmit={handleSubmit} translate={undefined}>
        <Box>
          <Field
            component={TextField}
            fullWidth
            type="text"
            label="Účel rezervace"
            name="purpose"
          />
        </Box>
        <LocalizationProvider dateAdapter={MomentAdapter} dateLibInstance={moment} /*locale={currentMoment.locale()}*/>
          <Box textAlign={'left'}>
            <Field
              name="termFromDate"
              disablePast
              component={DatePickerField}
              minDate={currentMoment}
              label="Datum počátku rezervace"
            />
            <Field
              name="termFromTime"
              component={TimePickerField}
              label="Čas počátku"
            />
          </Box>
          <Box textAlign={'left'}>
            <Field
              name="termToDate"
              disablePast
              component={DatePickerField}
              minDate={currentMoment}
              label="Datum konce rezervace"
            />
            <Field
              name="termToTime"
              component={TimePickerField}
              label="Čas konce"
            />
          </Box>
        </LocalizationProvider>
        <Box>
          <Field
            component={TextField}
            fullWidth
            type="text"
            label="Vaše jméno a příjmení"
            name="requesterName"
          />
        </Box>
        <Box>
          <Field
            component={TextField}
            fullWidth
            type="email"
            label="E-mail"
            name="requesterEmail"
          />
        </Box>
        <Box>
          <Field
            component={TextField}
            fullWidth
            type="text"
            label="Telefon"
            name="requesterPhone"
          />
        </Box>
        <Box>
          <Field
            component={TextField}
            fullWidth
            type="text"
            label="Poznámka"
            multiline
            rowsMax={4}
            name="note"
          />
        </Box>
        <Box mt={4}>
          {(saveStatus?.status === FetchStatus.ERROR) && !saveStatus?.errors &&
          <Alert severity={'error'} style={{ textAlign: 'left' }}>
            Rezervaci se nepodařilo uložit, zkontrolujte vyplněnou žádost a zkuste to znovu nebo nás kontaktujte jiným způsobem.
          </Alert>}
          {!isValid && submitCount > 0 && <Alert severity={'warning'} style={{ textAlign: 'left' }}>Rezervace ještě není správně vyplněna.</Alert>}
        </Box>
        <Box mt={4}>
          <EventSubmitButton defaultText='Odeslat rezervaci' loadingText='Odesílám rezervaci...' successText='Odesláno' saveStatus={saveStatus?.status} />
        </Box>
        <HomeLinkButton />
      </Form>
    )}
  </Formik>;
};
//TODO PCM doladit lokalizaci
export default EventCreate;