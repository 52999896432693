import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, IconButton, Link as MLink, Typography } from '@material-ui/core';
import { HomeLink } from './HomeLinkButton';
import MailIcon from '@material-ui/icons/Mail';

interface Props {
  showHomeButton?: boolean,
  footerClass?: string
}

const useStyles = makeStyles((theme) => ({
  section: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  footerRight: {
    textAlign: 'right',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center'
    }
  }
}));

const Footer: React.FC<Props> = (props) => {
  const classes = useStyles();

  return <Box pt={3} pb={3} component={'footer'} className={`${classes.section} ${props.footerClass}`}>
    {props.showHomeButton && <Box pb={3}>
      <HomeLink />
    </Box>}
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
    >
      <Grid item sm={4} xs={12}>
      </Grid>
      <Grid item sm={4} xs={12}>
        <Typography variant="body2" color="textSecondary" align="center">
          <MLink color="inherit" href="mailto:mohelno@sokol.eu"> <IconButton edge={'end'} disableFocusRipple={true} disableRipple={true}
            size={'small'}><MailIcon /></IconButton> mohelno@sokol.eu</MLink>
        </Typography>
        <Typography variant="body2" color="textSecondary" align="center">
          <MLink color="inherit" href="https://sokol-mohelno.cz/" target={'_blank'} rel={'noopener'}>
            Sokol Mohelno
          </MLink>{' © '}
          {new Date().getFullYear()}
        </Typography>
      </Grid>
      <Grid item sm={4} xs={12}>
        <Box pr={3} pl={3} className={classes.footerRight}>
          <Typography variant="body2" color="textSecondary">
            <MLink color="inherit" href="https://cimbalek.net/" target={'_blank'} rel={'noopener'}>
              Vyrobil&nbsp;<img alt="logo Přemysl Cimbálek" title="Logo cimbalek.net"
              src="https://cimbalek.net/images/cimbalek-net-logo-small.png" />&nbsp;Přemysl&nbsp;Cimbálek
            </MLink>{' '}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  </Box>;
};

export default Footer;
