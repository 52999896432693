import React from 'react';
import { ReservationStatus as Status } from '../services/types';

interface Props {
  status: Status
}

function resolveStatusString(status: Status) {
  switch (status) {
    case Status.APPROVED:
      return 'Schválená rezervace';
    case Status.CREATED:
      return 'Vytvořená rezervace, čeká na schválení';
    case Status.DELETED:
      return 'Zrušená rezervace';
    default:
      throw Error(`Unknown reservation status '${status}'`);
  }
}

const ReservationStatus: React.FC<Props> = (props) => {
  return <>{resolveStatusString(props.status)}</>;
};

export default ReservationStatus;
