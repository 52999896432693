import React from 'react';
import { FetchStatus } from '../services/types';
import { CircularProgress, Typography } from '@material-ui/core';
import { ErrorOutlineOutlined, RemoveCircleOutlineOutlined } from '@material-ui/icons';
import ErrorPage from './pages/ErrorPage';
import Page from './pages/Page';

interface Props {
  fetchStatus: FetchStatus
}

const FetchingPageContainer: React.FC<Props> = (props) => {
  switch (props.fetchStatus) {
    case FetchStatus.ERROR:
      return <ErrorPage message='Nastala neočekávaná chyba, zkuste to prosím později.' icon={ErrorOutlineOutlined} />;
    case FetchStatus.NOT_FOUND:
      return <ErrorPage message='Rezervace byla pravděpodobně již smazána.' icon={RemoveCircleOutlineOutlined} />;
    case FetchStatus.LOADING:
      return (<Page>
        <CircularProgress />
        <Typography variant="h6" display="block" align={'center'} color={'textSecondary'} gutterBottom={true}>
          Nahrávám data...
        </Typography>
      </Page>);
    case FetchStatus.SUCCESS:
      return <Page>{props.children}</Page>;
    default:
      throw Error(`Unsupported fetch status ${props.fetchStatus}`);
  }
};

export default FetchingPageContainer;
