import React from 'react';
import { Box, Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
}

const HomeLink: React.FC<Props> = () => {

  return <Link to={'/'} component={RouterLink}>
    Zpět na úvodní stránku
  </Link>;
};

const HomeLinkButton: React.FC<Props> = () => {
  return <Box mt={3}>
    <HomeLink />
  </Box>;
};

export { HomeLinkButton as default, HomeLink };
