import React from 'react';
import Page from './Page';
import { Box, Button, Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import CalendarFrame from '../CalendarFrame';
import { Link } from 'react-router-dom';
import TodayIcon from '@material-ui/icons/Today';
import HighlightedSection from '../HighlightedSection';
import { makeStyles } from '@material-ui/core/styles';
import EuroIcon from '@material-ui/icons/Euro';
import ScheduleIcon from '@material-ui/icons/Schedule';

const useStyles = makeStyles(theme => ({
  button: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2)
  },
  head: {
    backgroundColor: theme.palette.grey[100]
  },
  headingIcon: {
    marginBottom: '-3px'
  }
}));

interface Props {
}

const HomePage: React.FC<Props> = () => {
  const classes = useStyles();
  return (<Page>
    <HighlightedSection>
      <Typography variant={'h2'} gutterBottom={true}>Rezervace budovy</Typography>
      <Typography variant={'body2'} gutterBottom={true}>Vítejte v rezervačním systému na pronájem sokolovny v Mohelně.</Typography>
      <Box mt={5} mb={2}>
        <Link to={'/create'}>
          <Button
            className={classes.button}
            size="large"
            variant="contained"
            color="primary"
            startIcon={<TodayIcon />}
          >
            Nová rezervace
          </Button>
        </Link>
      </Box>
    </HighlightedSection>
    <Container maxWidth={'lg'}>
      <Typography id="aktualni-obsazenost" variant={'h5'} gutterBottom={true}>
        <ScheduleIcon className={classes.headingIcon} color={'primary'} />&nbsp;Aktuální obsazenost</Typography>
      <Paper>
        <Box m={1}>
          <CalendarFrame />
        </Box>
      </Paper>
      <Box mt={5}>
        <Typography id="cenik" variant={'h5'} gutterBottom={true}>
          <EuroIcon color={'primary'} className={classes.headingIcon} />&nbsp;Ceník</Typography>
          <Box mt={1} mb={3}>
          <Typography variant={'body2'} align={'center'}>Aktuální ceny naleznete v <a href="https://sokol-mohelno.cz/price-list.php" target="blank" title="Ceník služeb">Ceníku služeb</a>.
          </Typography>
        </Box>
        {/* <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead className={classes.head}>
              <TableRow>
                <TableCell>Akce</TableCell>
                <TableCell align="right">Cena</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key="l1">
                <TableCell component="th" scope="row">Pronájem - výdělečná akce (zábava, ples...)</TableCell>
                <TableCell align="right">4.000,- (nerozlišuje se člen/nečlen sokola)</TableCell>
              </TableRow>
              <TableRow key="l2">
                <TableCell component="th" scope="row">Pronájem - nevýdělečná akce/za noc (+příprava a úklid)</TableCell>
                <TableCell align="right">200,- člen / 500,- nečlen</TableCell>
              </TableRow>
              <TableRow key="l3">
                <TableCell component="th" scope="row">Pronájem - prodejci obuvi/oblečení</TableCell>
                <TableCell align="right">1.000,-</TableCell>
              </TableRow>
              <TableRow key="l4">
                <TableCell component="th" scope="row">Hodová zábava (pátek-neděle)</TableCell>
                <TableCell align="right">6.000,- (za nácviky a dopitky se nájem neplatí)</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Box mt={1} mb={3}>
          <Typography variant={'body2'} align={'left'}>Uvedené ceny jsou bez "médií" (voda, elektřina, topení), elektřina a voda se účtuje dle reálné
            spotřeby (případně
            voda paušálem), topení paušálem (mimo pronájmu prodejcům obuvi/oblečení, za ten je cena konečná). Ceny jsou smluvní, lze se dohodnout na jiném
            "způsobu" placení (dřevo, provedení různých prací, podíl ze zisku...).
          </Typography>
        </Box>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead className={classes.head}>
              <TableRow>
                <TableCell>Médium</TableCell>
                <TableCell align="right">Cena</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key="l5">
                <TableCell component="th" scope="row">Voda - paušál akce malého rozsahu (narozeniny...)</TableCell>
                <TableCell align="right">50,-</TableCell>
              </TableRow>
              <TableRow key="l6">
                <TableCell component="th" scope="row">Voda - paušál akce velkého rozsahu (zábava, ples)</TableCell>
                <TableCell align="right">300,-</TableCell>
              </TableRow>
              <TableRow key="l7">
                <TableCell component="th" scope="row">Voda</TableCell>
                <TableCell align="right">75,-/m<sup>3</sup></TableCell>
              </TableRow>
              <TableRow key="l8">
                <TableCell component="th" scope="row">Elektrická energie</TableCell>
                <TableCell align="right">7,-/kWh</TableCell>
              </TableRow>
            </TableBody>
          </Table>
            </TableContainer> */ }
      </Box>
    </Container>
  </Page>);
};

export default HomePage;
